import * as React from 'react'
import { Link } from 'gatsby'
import { ContentArea } from './component-library'

const ChickletGrid = ( props ) => {
  let gridItems = []

  props.data.forEach((item) => {
    gridItems.push(
      <Link key={item.slug} name='chickletGrid' to={`/${(item.underConstruction === true) ? 'under-construction' : item.slug}`} className='no-underline lg:w-[33%] w-fill h-fill'>
        <ContentArea className={`hover:bg-button-hover`}>
          <h3 className="text-title text-center min-w-fit">{item.seo.title + (item.underConstruction === true ? ' - Under Construction' : '')}</h3>
          <p className='text-body h-fill'>{item.seo.description}</p>
        </ContentArea>
      </Link>
    )
  })

  return (
    <div className={`flex lg:flex-row lg:space-x-10 lg:min-h-full flex-col lg:space-y-0 space-y-10 ${props.className}`}>
      {gridItems}
    </div>
  )
}

export default ChickletGrid