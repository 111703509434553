import * as React from "react"

const Seo = ({ title, description, seoImage, favicon, keywords, children, url }) => {
  return (
    <>
      <title>{title}</title>

      <link rel="icon" type="image/png" href={favicon} />
      
      <meta name="google-site-verification" content="UtysO-pU0fHhcV9InkSc-O9XmRrl9o5qJw2L0Vt9pvQ" />
      <meta name="google" content="nositelinkssearchbox"/>
      <meta name="keywords" content={keywords}/>
      <meta name="description" content={description}/>

      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Travis Cucore"/>   
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description}/>
      <meta property="og:image" content={seoImage}/>
      <meta property="og:url" content={url}/>
      {children}
    </>
  )
}

export default Seo
