import * as React from 'react'
import ProjectsCard from './projects-card';
import { GatsbyImage } from 'gatsby-plugin-image';


const EmployerCard = ( { employer, projects, isEven, startDate, endDate, onEmployerSelect, selectedEmployer, lastOne } ) => {

  //Event handler for when the user selects an employer by any of the 3-buttons.  
  //Fires an event passing the source to the parent timeline, which updates the selected employer Id.
  const handleEmployerSelect = React.useCallback( (event) => {
    onEmployerSelect(event);
  }, [onEmployerSelect]);

  return (
    /** this li contains everything needed to describe the employer and associated project history. */
    <li key={employer.id}  
        className={`flex flex-col min-h-auto max-h-auto lg:mb-0 lg:px-0 px-5 mb-10 ${isEven? 'lg:flex-row-reverse' : 'lg:flex-row'} lg:min-h-[275px]`}>
      {/* This is the Employer Card*/}
      <div id={employer.id} name="employer-card"  
        className={`flex-grow flex-col lg:w-[50%] lg:mb-0 mb-10 opacity-100 transform-translate duration-1000 ease-in-out ${`${(selectedEmployer === employer.id) ? `${isEven ? 'lg:translate-x-[-45vw] lg:pr-20' : 'lg:translate-x-[45vw] lg:pl-20'}` : 'translate-x-[0%]'}`}`
        }>
        <h2 className={`flex-fit text-title mb-0 ${isEven ? 'lg:text-start' : 'lg:text-end'}`}>{employer.employerName}</h2>
        <div className={`flex-grow lg:flex hidden flex-wrap ${isEven ? '' : 'lg:flex-wrap-reverse'}`}>
          <p className="flex-fit lg:flex-start text-label self-end mb-1">{employer.role}</p>
          <div className="flex-grow"></div>
          <p className="flex-fit lg:flex-start text-center text-label lg:self-end mb-1">{`${startDate} to ${endDate === 'Invalid Date' ? 'Current' : endDate}`}</p>
        </div> 
        <p className={`p-0 m-0 h-fit text-start`}>{employer.summaryOfRole}</p>
        <button name={employer.id} onClick={handleEmployerSelect} 
          className={`lg:block hidden underline text-link hover:text-accent-bright text-center lg:text-end`}>
            Reveal Project Work
        </button>
      </div>
      {/* Employer badge.  This is one of the buttons */}
      <div className={`flex-none lg:flex flex-col mx-3 p-0 hidden min-w-fit`}>
        <button aria-label="reveal projects" label={`Reveal Projects`} id="badge" className="min-h-max min-w-max" onClick={handleEmployerSelect}>
          <GatsbyImage name={employer.id} className={`rounded-full shadow-shadow-black shadow-lg ring-accent-bright hover:cursor-pointer p-0`} 
            image={employer.logo.localFile.childImageSharp.gatsbyImageData} alt=""/>
        </button>
        <div className={`h-full w-[50%] border-r-2 border-r-accent-strong lg:block hidden`}/>
      </div>
      {/* Project Card.  This is what renders when the employer is selected. */}
      <ProjectsCard projects={projects} showProjects={(employer.id === selectedEmployer) ? true : false} isEven={isEven}/>
    </li>
  )
}

//Memo prevents the function component from re-rendering when a prop is updated.
export default React.memo(EmployerCard)