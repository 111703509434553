import * as React from 'react'

const Button = ( { label, clickHandler, formId, className, type } ) => {

  return (
    <div className={className}>
      <button key={label} onClick={clickHandler}
        type={type}
        form={formId}
        className={`ring-1 ring-blend-shadow shadow-shadow-black shadow-md
          bg-button hover:bg-button-hover
          text-label rounded-xl p-2 h-full w-full
        `}>
        {label}
      </button>
    </div>

  )
}

export default Button