import * as React from "react"
import { Link, graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Button } from './component-library'

const Header = (location) => {

  const certifications = useStaticQuery(graphql`
    query {
      allGraphCmsCertification {
        nodes {
          certificationName
          id
          platform
          issuingAuthorityImage {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 75, height: 75)
              }
            }
          }
        }
      }
    }
  `) 

  let badges = [];
  
  certifications.allGraphCmsCertification.nodes.forEach((cert, index) => {
    badges.push(
      <div key={index} className={`flex-fit`}>
        <GatsbyImage image={cert.issuingAuthorityImage.localFile.childImageSharp.gatsbyImageData} alt=""/>
      </div>
    )
  });

  return (
    <header className="flex flex-col sm:flex-row w-full py-5 px-10 z-50 bg-primary max-h-fit place-items-center border-b-2 border-primary-muted shadow-b-lg shadow-shadow-black
                        lg:sticky lg:top-0">
      <Link to="/" className="shrink lg:flex-none flex-grow h-full no-underline group">
        <div className={`no-underline`}>
          <p className="text-4xl text-center sm:text-start text-secondary mb-auto">Travis Cucore</p>
        </div>
        <p className="sm:flex hidden lg:text-center text-label">Software Engineer | Technologist</p>
      </Link>
      <div className="grow hidden lg:flex flex-row place-items-center place-content-center gap-10">{badges}</div>
      <Link to="/book-a-meeting" className="lg:shrink rounded-lg self-center p-2 shadow-md ">
        <Button label="Schedule Meeting" data="contactMe_btn"className={`rounded-lg self-center p-2 shadow-md`}/>
      </Link>
    </header>
  )
}

export default Header
