import * as React from 'react'
import { Link } from 'gatsby';
import { ContentArea } from './component-library'

/* Need to parameterize this.  Should accept post query filter, number of posts to retrieve, and what else?*/

const RelatedList = (props) => {
   let posts = [];
   //("props.posts.length: ", props.posts)
   if(props.posts.length === 0){
      //console.log("no posts found");
      posts.push(
         <div>
            This author does not have any other content.  Check back soon.  We're sure they're working on interesting things to write about!
         </div>
      )
   }
   props.posts.forEach( (post, index) => {
      posts.push(
         <div key={index}>
            <Link key={post.id} to={`/${post.slug}`} className="no-underline">
               <div className="flex flex-col rounded-md p-3 px-5
                  shadow-md ring-blend-shadow ring-1 shadow-shadow-black
                  hover:bg-button-hover
                  hover:cursor-pointer">
                  <div className="flex-none">
                     <div className="flex flex-row min-w-fit min-h-fit">
                        <h4 className="flex-grow text-start text-md">{post.title}</h4>
                        <h4 className="flex-auto text-end text-md">{post.date}</h4>
                     </div>
                  </div>
                  <div className="flex-auto min-w-fit min-h-fit">
                     <p className="flex-grow">{post.seo.description}</p>
                  </div>
               </div>
            </Link>
         </div>
      )
   })
   return (
      <ContentArea>
         <h3 className="text-center max-h-[30%] text-title">{props.title}</h3>
         <div className="flex flex-col space-y-3">
            {posts}
         </div>
      </ContentArea>
   )
}

export default RelatedList