import * as React from 'react'


const ContentArea = ({ children, className }) => {
  return (
    <div className={`rounded-2xl shadow-lg shadow-shadow-black ring-1 ring-blend-shadow p-5 bg-primary ${className}`}>
      {children}
    </div>
  )
}

export default ContentArea