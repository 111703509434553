import * as React from 'react';

const EmploymentProjectCard = ( { summary, contributions, role, industry, name, projectId, isEven } ) => {

  return (
    <li key={projectId} id={projectId} className={`inline-table`}>
      <div className={`flex-col lg:w-fill pb-0 mb-0`}>
        <h2 className={`flex-fit text-title mb-0 ${isEven ? 'lg:text-start' : 'lg:text-end '}`}>{name}</h2>
        <div className={`flex-grow flex flex-wrap ${isEven ? '' : 'lg:flex-wrap-reverse'}`}>
          <p className="flex-fit lg:flex-start text-center text-label self-end mb-1">{role}</p>
          <div className="flex-grow"></div>
          <p className="flex-fit lg:flex-start text-center text-label self-end mb-1">{industry}</p>
        </div>
        <p className={`p-0 m-0 h-fit`} dangerouslySetInnerHTML={ { __html: summary.html } }/>
        <p className={`p-0 m-0 h-fit`} dangerouslySetInnerHTML={ { __html: contributions.html } }/>
      </div>
    </li>
  )
}

//Memo prevents the card from re-rendering when the props are updated.
export default React.memo(EmploymentProjectCard)