import * as React from "react"
import { Header } from '../components/component-library'

const DefaultLayout = ( { children } ) => {
  return (
      <div className="lg:fixed lg:h-full lg:w-full">
        <Header/>
        {children}
      </div>
  )
}

export default DefaultLayout
