import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { GatsbyImage } from 'gatsby-plugin-image'
import { ContentArea } from './component-library'

const Carousel = ( props ) => {

  //Constants for easier readability.
  const NEXT_BTN = 'next_btn'
  const BACK_BTN = 'back_btn'

  const [currentIndex, setCurrentIndex] = useState(0);

  //Cycle the carousel to the next item every 5-seconds
  useEffect(() => {
    const interval = setInterval(() => {
      cycleIndex(currentIndex)
    }, props.interval);
    return () => clearInterval(interval);
  }, [currentIndex])
  
  let posts = []; //will hold html for each post
  

  props.data.forEach(element => {    
    posts.push(
      <Link key={element.slug} name="carouselItem" to={`/${element.slug}`}
        className={`no-underline transition ease-in-out duration-1000 delay-100 min-w-full h-inherit p-5`} 
        style={{transform: `translateX(-${currentIndex*100}%)`}}>
        <ContentArea className={`flex flex-row hover:bg-button-hover`}>
            <GatsbyImage
              image={element.seo.seoImage.localFile.childImageSharp.gatsbyImageData}
              alt=''
              className="xl:flex xl:flex-none hidden rounded-2xl min-w-fit"/>
            <div className="ml-5 flex-grow">
              <h1 className="text-center min-w-fit text-title">{element.seo.title}</h1>
              <div>
                <p className="text-2xl text-body">{element.seo.description}</p>
              </div>
            </div>
        </ContentArea>
      </Link>
    )
  })

  const cycleIndex = (event) => {
    let maxIndex = posts.length - 1;

    switch(event?.target?.value){
      case NEXT_BTN: {
        setCurrentIndex(((currentIndex >= maxIndex) ? 0 : (currentIndex + 1)));
        break;
      }

      case BACK_BTN: {
        setCurrentIndex(((currentIndex <= 0) ? maxIndex : (currentIndex - 1)));
        break;
      }

      //If called by interval
      default: {
        setCurrentIndex((currentIndex >= maxIndex) ? 0 : (currentIndex + 1));
        break;
      }
    }
  }
  
	return (
    <div className={`flex flex-row items-center ${props.className}`}>
      <button key={NEXT_BTN} onClick={cycleIndex} value={NEXT_BTN}
        className={`ring-1 ring-blend-shadow shadow-shadow-black shadow-md
          bg-button hover:bg-button-hover
          text-label rounded-full h-fit w-fit px-5 py-3
        `}>
        {`<`}
      </button>      
      <div className={`flex flex-row overflow-hidden mx-10 align-self-center`}>
        {posts}
      </div>
      <button key={BACK_BTN} onClick={cycleIndex} value={BACK_BTN}
        className={`ring-1 ring-blend-shadow shadow-shadow-black shadow-md
          bg-button hover:bg-button-hover
          text-label rounded-full h-fit w-fit px-5 py-3
        `}>
        {`>`}
      </button>    
    </div>
	)
}

export default Carousel