import * as React from 'react'

const ProjectsCard = ( {employerId, projects, showProjects, isEven } ) => { 

  return (
    <>
      <div key={`projects:${employerId}`}
        name={`projects:${employerId}`}
        className={`flex-grow flex-col will-change-transform lg:w-[50%] w-[100%] pr-5 transform-translate duration-1000 ease-in-out
          ${showProjects ? 'lg:delay-1000 lg:overflow-y-auto lg:overscroll-auto lg:h-[500px] h-fit opacity-100 flex' : 'overflow-hidden lg:h-0 lg:opacity-0'}
          lg:block lg:mb-0 ${isEven ? 'lg:translate-x-[49vw] lg:pl-6' : 'lg:-translate-x-[49vw] lg:pr-6'}
          `}>
        <h4 className={`text-4xl text-center`}>Projects</h4>
        <ul className='lg:absolute lg:top-10 lg:right-0 lg:left-0 lg:bottom-0 lg:overflow-y-auto px-3 lg:pt-3'>
          {projects}
        </ul>
      </div>
    </>

  )
}

export default React.memo(ProjectsCard)